body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  width: 100%;
  overflow: hidden;

  /* transparent is important as the videoplayer is behind the app */
  background: transparent;
}
@media (max-height: 1079px) {
  body {
    zoom: 0.67;
  }

  body.platform-vewd {
    zoom: 0.66;
  }
}

* {
  box-sizing: border-box;
  outline: none;
}
a {
  color: white;
  text-decoration: none;
}
button {
  font-family: inherit;
}
img {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: opacity 0.5s;
  opacity: 1;
}
img[src^='data'] {
  opacity: 0;
}

.unstyled-button {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  color: inherit;
  font-family: inherit;
}

p {
  margin: 0;
}
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
